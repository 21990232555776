import axios from "axios";
import { API_BASE_URL } from "./settings.api";

export interface ServiceInterface {
    name: string;
    key: string;
    value: number;
}

export const getServices = async (): Promise<ServiceInterface[]> => {
    let result = [];
    try {
        const response = await axios.get(`${API_BASE_URL}/services`);
        result = response.data;
    } catch (err) {
        console.log(err);
    }
    return result;
}


export interface TimeAvailableParams {
    party: string;
}

export const getTimeAvailable = async (params: TimeAvailableParams): Promise<number[]> => {
    let result = [];
    try {
        const response = await axios.get(`${API_BASE_URL}/services/time/available`, {
            params
        });
        result = response.data;
    } catch (err) {
        console.log(err);
    }
    return result;
}

export enum ServiceDurationType {
    FIXED = 'fixed',
    PER_GUEST = 'per_guest'
};

export interface ServiceDurationPriceInterface {
    type: ServiceDurationType;
    guests: number;
    time: number;
    price: number;
}

export interface ServiceDurationPriceParams {
    party: string;
    guests: number;
    duration: number;
}

export const getServiceDurationPrice = async (params: ServiceDurationPriceParams): Promise<ServiceDurationPriceInterface> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/services/time`, {
            params
        });
        return response.data as ServiceDurationPriceInterface;
    } catch (err) {
        console.log(err);
    }
}
