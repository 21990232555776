import axios from "axios";
import { API_BASE_URL } from "./settings.api";

export interface PartyInterface {
    name: string;
    order: number;
    promotion?: number;
    sale_end?: Date;
    sale_start?: Date;
    active: boolean;
}

export const getParties = async (): Promise<PartyInterface[]> => {
    let result = [];
    try {
        const response = await axios.get(`${API_BASE_URL}/parties`);
        result = response.data;
    } catch (err) {
        console.log(err);
    }
    return result;
}

export interface PartyInputs {
    minGuestCount?: number;
    maxGuestCount?: number;
}

export interface PartyInputsParams {
    party: string;
}

export const getPartyInputs = async (params: PartyInputsParams): Promise<PartyInputs> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/parties/guestCount`, {
            params
        });
        return response.data;
    } catch (err) {
        console.log(err);
    }
}
