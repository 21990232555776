import axios from "axios";
import { API_BASE_URL } from "./settings.api";
import qs from 'qs';

interface DrinkPhoto {
    title: string;
    gatsbyImageData: any;
}

export interface DrinkInterface {
    contentful_id: string | number;
    contentfulid?: string | number;
    name: string;
    desc?: any;
    photo?: DrinkPhoto;
}

export const getDrinks = async (): Promise<DrinkInterface[]> => {
    try {
        const response = await axios(`${API_BASE_URL}/drinks`);
        return response.data as DrinkInterface[];
    } catch (err) {
        console.log(err);
    }
}

export interface DrinksAvailableParams {
    bundles: string[];
    offer: string;
}

export const getAvailableDrinks = async (params: DrinksAvailableParams): Promise<string[]> => {
    const selected = [params.offer, ...params.bundles];

    try {
        const response = await axios(`${API_BASE_URL}/drinks/available`, {
            method: "GET",
            params: {
                selected
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });
        return response.data as string[];
    } catch (err) {
        console.log(err);
    }
}
