import axios from "axios";
import { BundleInterface } from "../components/Bundles/Bundles";
import { API_BASE_URL } from "./settings.api";

export interface OfferInterface {
    name: string;
    price: number;
}

export const getOffers = async (party: string, guests: number): Promise<OfferInterface[]> => {
    let result = [];
    try {
        const response = await axios(`${API_BASE_URL}/offers`, {
            method: 'GET',
            params: {
                party,
                guests
            }
        });
        result = response.data;
    } catch (err) {
        console.log(err);
    }
    return result;
}

export const getBundles = async (party: string, guests: number): Promise<BundleInterface[]> => {
    let result = [];
    try {
        const response = await axios(`${API_BASE_URL}/offers/bundles`, {
            method: 'GET',
            params: {
                party,
                guests
            }
        });
        result = response.data;
    } catch (err) {
        console.log(err);
    }
    return result;
}
