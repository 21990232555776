import React from "react";
import { createContext } from "react"
import { DistanceInterface, getDistancePrice } from "../api/distance.api";
import { getAvailableDrinks } from "../api/drinks.api";
import { getBundles, getOffers, OfferInterface } from "../api/offer.api";
import { getParties } from "../api/parties.api";
import { getServiceDurationPrice, getServices, getTimeAvailable, ServiceInterface } from "../api/services.api";
import { BundleInterface } from "../components/Bundles/Bundles";

export interface Totals {
    totals: number;
    offer: number;
    service: number;
    duration: number;
    distance: number;
    bundle: number;
}

type AppContextType = {
    parties: any[],
    selectedParty: string | null;
    selectParty: (party: string) => void;
    date: Date,
    selectDate: (date: Date) => void;
    selectedPlace: string,
    selectPlace: (place: string) => void;
    duration: number,
    guestCount: number,
    updateGuestCount: (count: number) => void;
    acceptAlcoholBuy: boolean,
    acceptProductsBuy: boolean,
    offers: OfferInterface[],
    selectedOffer?: string;
    selectOffer: (offer: OfferInterface) => void;
    bundles: BundleInterface[],
    selectedBundles: string[],
    toggleSelectedBundles: (name: string) => void;
    totals: Totals;
    services: ServiceInterface[],
    selectedServices: string[],
    toggleSelectedServices: (service: string) => void;
    distance?: DistanceInterface;
    updateDistance: (distance: DistanceInterface) => void;
    durationsAvailable: number[];
    selectedDuration: number;
    selectDuration: (duration: number) => void;
    durationPrice: number;
    availableDrinks: string[];
    clientStocking: boolean;
    toggleClientStocking: () => void;
}

const AppContextDefault: AppContextType = {
    parties: [],
    selectedParty: "",
    selectParty: () => { },
    date: new Date,
    selectDate: (date: Date) => { },
    selectedPlace: "",
    selectPlace: () => { },
    duration: 2,
    guestCount: 1,
    updateGuestCount: () => { },
    acceptAlcoholBuy: true,
    acceptProductsBuy: false,
    offers: [],
    selectOffer: () => { },
    bundles: [],
    selectedBundles: [],
    toggleSelectedBundles: (name: string) => { },
    services: [],
    selectedServices: [],
    toggleSelectedServices: (service: string) => { },
    totals: {
        totals: 0,
        offer: 0,
        service: 0,
        duration: 0,
        distance: 0,
        bundle: 0
    },
    updateDistance: () => { },
    durationsAvailable: [],
    selectedDuration: 0,
    selectDuration: (duration: number) => { },
    durationPrice: 0,
    availableDrinks: [],
    clientStocking: false,
    toggleClientStocking: () => { }
}

export const AppContext = createContext<AppContextType>(AppContextDefault);

export const AppContextProvider: React.FC = ({ children }) => {
    const [parties, setParties] = React.useState<any[]>([]);
    React.useEffect(() => {
        if (parties.length > 0) return;
        getParties()
            .then(res => {
                setParties(res
                    .filter(el => el.active)
                    .sort((a, b) => a.order - b.order));
            });
    }, []);

    // date
    const [date, setDate] = React.useState<Date>(new Date);
    const selectDate = (date: Date) => setDate(date);

    // guest count
    const [guestCount, setGuestCount] = React.useState<number>(1);
    const updateGuestCount = (count: number) => {
        setGuestCount(count > 0 ? count : 1);
    }

    // selected party
    const [selectedParty, setSelectedParty] = React.useState<string | null>(null);
    const selectParty = (party: string) => setSelectedParty(party);

    const [selectedPlace, setSelectedPlace] = React.useState<string>("");
    const selectPlace = (place: string) => setSelectedPlace(place);

    // distance
    const [distance, setDistance] = React.useState<DistanceInterface>();
    React.useEffect(() => {
        if (selectedPlace.length < 1) return;
        getDistancePrice(selectedPlace).then(response => setDistance(response));
    }, [selectedPlace]);

    // offers
    const [offers, setOffers] = React.useState<OfferInterface[]>([]);
    React.useEffect(() => {
        if (!selectedParty || (selectedParty && selectedParty.length < 1)) return;

        getOffers(selectedParty, guestCount).then(res => {
            setOffers(res);
        })
    }, [guestCount, selectedParty]);

    const [selectedOffer, setSelectedOffer] = React.useState<string>();
    const selectOffer = ((offer: OfferInterface) => setSelectedOffer(offer.name));

    // bundles
    const [selectedBundles, setSelectedBundles] = React.useState<string[]>([]);
    const toggleSelectedBundles = (name: string) => {
        if (selectedBundles.includes(name)) {
            // removes bundle
            setSelectedBundles(prev => prev.filter(el => el !== name));
        } else {
            // adds bundle
            setSelectedBundles(prev => [...prev, name]);
        }
    }

    const [bundles, setBundles] = React.useState<BundleInterface[]>([]);
    React.useEffect(() => {
        const filteredOffer = offers.filter(el => el.name === selectedOffer);
        const offer = filteredOffer[0];

        if (!offer || (offer && offer.price !== 0)) {
            setSelectedBundles([]);
            setBundles([]);
            return;
        }

        getBundles(selectedParty, guestCount).then(res => setBundles(res));

    }, [selectedOffer, guestCount]);

    // services
    const [services, setServices] = React.useState<ServiceInterface[]>([]);
    React.useEffect(() => {
        getServices().then(response => setServices(response));
    }, []);

    const [selectedServices, setSelectedServices] = React.useState<string[]>([]);
    const toggleSelectedServices = (name: string) => {
        if (selectedServices.includes(name)) {
            // removes bundle
            setSelectedServices(prev => prev.filter(el => el !== name));
        } else {
            // adds bundle
            setSelectedServices(prev => [...prev, name]);
        }
    }

    // client stocking
    const [clientStocking, setClientStocking] = React.useState<boolean>(false);
    const toggleClientStocking = () => setClientStocking(prev => !prev);


    const [durationsAvailable, setDurationsAvailable] = React.useState<number[]>([]);
    const [selectedDuration, setSelectedDuration] = React.useState<number>(0);
    const selectDuration = (duration: number) => setSelectedDuration(duration);

    React.useEffect(() => {
        if (guestCount < 1 || !selectedParty) return;

        getTimeAvailable({ party: selectedParty }).then((res: number[]) => {
            setDurationsAvailable(res);
            setSelectedDuration(res[0] || 0); // sets first duration available on download
        });

    }, [selectedParty]);

    const [durationPrice, setDurationPrice] = React.useState<number>(0);
    React.useEffect(() => {

        getServiceDurationPrice({
            guests: guestCount,
            party: selectedParty,
            duration: selectedDuration
        }).then(res => {
            res.price && setDurationPrice(res.price || 0);
        }).catch((err) => {
            console.log(err);
        });

    }, [guestCount, selectedParty, selectedDuration]);



    // available drinks
    const [availableDrinks, setAvailableDrinks] = React.useState<string[]>([]);
    React.useEffect(() => {
        if (selectedBundles.length < 1 && !selectedOffer) return;
        getAvailableDrinks({ bundles: selectedBundles, offer: selectedOffer }).then((response) => {
            setAvailableDrinks(response);
        });
    }, [selectedBundles, selectedOffer]);

    // totals
    const [totals, setTotals] = React.useState<Totals>({
        totals: 0,
        offer: 0,
        service: 0,
        duration: 0,
        distance: 0,
        bundle: 0
    });

    React.useEffect(() => {
        const distancePrice = distance?.price || 0;
        const servicePrice = services
            .filter(el => selectedServices.includes(el.name))
            .reduce((prev, curr) => prev + curr.value, 0);
        const offerPrice = offers
            .filter(el => el.name === selectedOffer)
            .reduce((prev, curr) => prev + curr.price, 0);
        const bundlePrice = bundles
            .filter(el => selectedBundles.includes(el.name))
            .reduce((prev, curr) => prev + curr.price, 0);

        const totals = distancePrice + servicePrice + durationPrice + (!clientStocking ? bundlePrice + offerPrice : 0);

        setTotals({
            totals,
            offer: offerPrice,
            service: servicePrice + durationPrice,
            duration: durationPrice,
            distance: distancePrice,
            bundle: bundlePrice
        });

    }, [distance, offers, selectedBundles, bundles, services, selectedServices, selectedOffer, durationPrice, clientStocking]);

    return (
        <AppContext.Provider value={{
            ...AppContextDefault,
            guestCount,
            updateGuestCount,
            parties,
            selectedParty,
            selectParty,
            selectedPlace,
            selectPlace,
            offers,
            totals,
            distance,
            selectedOffer,
            selectOffer,
            bundles,
            selectedBundles,
            toggleSelectedBundles,
            services,
            selectedServices,
            toggleSelectedServices,
            date,
            selectDate,
            durationsAvailable,
            selectedDuration,
            selectDuration,
            durationPrice,
            availableDrinks,
            clientStocking,
            toggleClientStocking
        }}>
            {children}
        </AppContext.Provider>
    )
}